<template>
  <div class="">
    <vehicle-sidebar
        v-model="isVehicleHandlerSidebarActive"
        :vehicle="vehicle"
        :clear-data="clearData"
        @add-vehicle="addVehicle"
        @update-vehicle="updateVehicle"
    />
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="7"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de vehículos</h3> <small> (Hay {{ totalVehicles }} vehículos)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
              <b-button
                variant="primary"
                @click="isVehicleHandlerSidebarActive = true"
              >
                Crear
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refVehicleListTable"
        :items="fetchVehicles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        class="position-relative"
      >
        <!-- Column: Id -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mx-1"
              size="16"
              @click="editVehicle(data.item)"
            />
            <b-tooltip
              title="Editar"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer"
              @click="deleteVehicle(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`invoice-row-${data.item.id}-delete-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!--        <b-col-->
          <!--          cols="12"-->
          <!--          sm="6"-->
          <!--          class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
          <!--        >-->
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          <!--        </b-col>-->
          <!-- Pagination -->

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BRow,
  BTable, BTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vehicleStoreModule from '@/views/dramox/guides/vehicle/vehicleStoreModule'
import VehicleSidebar from '@/views/dramox/guides/vehicle/sidebar/vehicleSidebar'

export default {
  name: 'HomeBusiness',
  components: {
    VehicleSidebar,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BTooltip,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-guide-vehicles'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, vehicleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const isVehicleHandlerSidebarActive = ref(false)
    const refVehicleListTable = ref(null)
    const totalVehicles = ref(0)

    const blankDriver = {
      brand: '',
      registrationNumber: '',
    }
    const vehicle = ref(JSON.parse(JSON.stringify(blankDriver)))
    const clearData = () => {
      vehicle.value = JSON.parse(JSON.stringify(blankDriver))
    }

    const tableColumns = [
      { key: 'brand', label: 'Marca', sortable: false },
      { key: 'registrationNumber', label: 'Placa', sortable: false },
      { key: 'actions', label: 'Acciones' },
    ]

    const refetchData = () => {
      refVehicleListTable.value.refresh()
    }

    const editVehicle = item => {
      vehicle.value = item
      isVehicleHandlerSidebarActive.value = true
    }
    const addVehicle = item => {
      store.dispatch('app-guide-vehicles/addVehicle', { body: item })
        .then(() => {
          refetchData()
          isVehicleHandlerSidebarActive.value = false
          showNotification({ title: 'Vehiculo creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const updateVehicle = item => {
      store.dispatch('app-guide-vehicles/updateVehicle', { id: item.id, body: item })
        .then(() => {
          refetchData()
          isVehicleHandlerSidebarActive.value = false
          showNotification({ title: 'Vehiculo actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteVehicle = item => {
      store.dispatch('app-guide-vehicles/deleteVehicle', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Vehiculo eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const fetchVehicles = (ctx, callback) => {
      store
        .dispatch('app-guide-vehicles/fetchVehicles')
        .then(response => {
          const { results } = response.data.payload

          callback(results)
          totalVehicles.value = results.length
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching categories list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      isVehicleHandlerSidebarActive,
      vehicle,

      fetchVehicles,
      tableColumns,
      refVehicleListTable,
      totalVehicles,

      refetchData,

      clearData,
      editVehicle,
      addVehicle,
      updateVehicle,
      deleteVehicle,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
