import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVehicles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/vehicles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addVehicle(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/vehicle/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVehicle(ctx, { id,body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`admin/vehicle/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVehicle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/vehicle/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
